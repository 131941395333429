import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../../../constants/urls";

/**
 * Método encargado de obtener todas las aplicaciones.
 * @returns Colección de aplicaciones.
 */
export async function GetAplicaciones() {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/aplicaciones`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener una aplicación, por su ID.
 * @param {*} aplicacionID ID de la aplicación.
 * @returns Datos de la aplicación.
 */
export async function GetAplicacion(aplicacionID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/aplicaciones/${aplicacionID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar una aplicación.
 * @param {*} aplicacion Datos de la aplicación.
 * @returns Dato agregado.
 */
export async function AddAplicacion(aplicacion) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/aplicaciones`;
    let response = await Axios.post(url, aplicacion);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar una aplicación.
 * @param {*} aplicacion Datos de la aplicación.
 * @returns Dato actualizado.
 */
export async function UpdateAplicacion(aplicacion) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/aplicaciones/${aplicacion._id}`;
    let response = await Axios.put(url, aplicacion);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar una aplicación.
 * @param {*} aplicacionID ID de la aplicación.
 * @returns Respuesta de la eliminación.
 */
export async function DeleteAplicacion(aplicacionID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/aplicaciones/${aplicacionID}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}