import { object, string } from "yup";

export const FormikInitialValues = {
	email: "",
	razon: "",
}

export const FormikValidationSchema = object().shape({
	email: string()
		.email("El correo debe tener el formato \"usuario@dominio\".")
		.required("El correo es requerido."),
	razon: string()
		.min(3, ({ min }) => `La razón debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La razón debe tener a lo más ${max} caracteres.`)
		.required("La razón es requerida."),
});