import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/analytics';
import axios from 'axios';
import * as URLS from '../constants/urls';

const config = {
  apiKey: "AIzaSyDiRfYh144WXcsui3Ok_DfF-rnm84OgbTk",
  authDomain: "cydingenieria-proyectos.firebaseapp.com",
  databaseURL: "https://cydingenieria-proyectos.firebaseio.com",
  projectId: "cydingenieria-proyectos",
  storageBucket: "cydingenieria-proyectos.appspot.com",
  messagingSenderId: "908645375548",
  appId: "1:908645375548:web:17aaaf037808a343",
  measurementId: "G-M2NG13KLWK"
};

const defaultProject = firebase.initializeApp(config);

export const auth = defaultProject.auth();
export const realTime = defaultProject.database();
export const analytics = defaultProject.analytics();

export function onAuthStateChange(callback) {
  auth.onAuthStateChanged(user => {
    if (user) {
      callback({ loggedIn: true, email: user.email });
    } else {
      callback({ loggedIn: false });
    }
  });
}

export async function onPermissionCheck() {
  var provider = new firebase.auth.GoogleAuthProvider();

  let currenToken = localStorage.getItem("csrfToken");

  provider.addScope('https://www.googleapis.com/auth/calendar.events');
  provider.addScope('https://www.googleapis.com/auth/calendar.settings.readonly');
  provider.addScope('https://www.googleapis.com/auth/calendar');
  let resultToken = {}
  try {
    await axios.post(`${URLS.REDIRECT_BASE}/verifySessionCookie`, {}, {
      withCredentials: true,
      crossDomain: true,
      params: {
        _csrf: currenToken
      }
    })
  } catch (error) {
    console.log(error)
  }

  resultToken["user"] = firebase.auth().currentUser
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(resultToken);
    }, 1500)
  });
}