import React from "react";
import { Box, Card, CardActionArea, CardContent, Chip, makeStyles, Typography } from "@material-ui/core";
import { ChevronRight as ChevronRightIcon } from "@material-ui/icons";

/**
 * Componente para aplicaciones destacadas.
 * @param {*} link_redirect URL de redirección la hacer click.
 * @param {*} link_icon URL del icono de la aplicación.
 * @param {*} text Nombre de la aplicacion.
 * @param {*} text_more Descripción de la aplicación.
 * @param {*} text_button Texto del botón.
 * @returns 
 */
export default function CardAppDestacada(props) {
  const {
    link_redirect,
    link_icon,
    text,
    text_more,
    text_button,
  } = props;

  const classes = useStyles();

  return (
    <Card elevation={0} variant="outlined" className={classes.card}>
      <CardActionArea href={link_redirect} target="_blank" rel="noreferrer">
        <CardContent className={classes.card_contenido}>
          {/* ICONO DE APLICACION */}
          <img src={link_icon} alt="fotografia" className={classes.image} />

          {/* NOMBRE DE APLICACION */}
          <Typography gutterBottom variant="h5" component="h2">
            {text}
          </Typography>

          {/* DESCRIPCION DE APLICACION */}
          <Typography variant="body2" color="textSecondary" align="left" className={classes.text_more}>
            {text_more}
          </Typography>

          {/* BOTON PARA REDIRECCIONAR */}
          <Box display="flex" justifyContent="flex-end">
            <Chip
              label={text_button}
              color="primary"
              onDelete={() => { }}
              deleteIcon={<ChevronRightIcon />}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "20px",
  },
  card_contenido: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
  },
  image: {
    height: "80px",
    paddingBottom: "5px"
  },
  text_more: {
    alignItems: "center",
    height: "113px",
  }
}));