import Axios from "axios";
import { PORTAFOLIO } from "../../../constants/urls";

/**
 * Método encargado de obtener todas las gerencias.
 * @returns Colección de gerencias.
 */
export async function GetGerencias() {
  try {
    let url = `${PORTAFOLIO}/gerencias`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener una gerencia, por su ID.
 * @param {*} gerenciaID ID de la gerencia.
 * @returns Colección de gerencias.
 */
export async function GetGerencia(gerenciaID) {
  try {
    let url = `${PORTAFOLIO}/gerencias/${gerenciaID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}