import React, { useState, useContext } from "react";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { Collapse, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore, CardTravelTwoTone, FiberManualRecordTwoTone, Tune, Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { MainContext } from "../../App";
import * as Routes from "../../constants/routes";
import * as Permissions from "../../constants/permissions";

export default function MenuLateral({ open, setOpen }) {
	const { permisos } = useContext(MainContext);
	const classes = useStyles();
	const [openAccesos, setOpenAccesos] = useState(false);
	const [openAccesosAdministracion, setOpenAccesosAdministracion] = useState(false);

	const handleClick = () => {
		setOpenAccesos(!openAccesos);
	}

	const handleClickAdministracion = () => {
		setOpenAccesosAdministracion(!openAccesosAdministracion);
	}

	function abrirMenuConAccesos() {
		if (open === false) {
			setOpen(true);
		}
		handleClick();
	}

	function abrirAdministracionConAccesos() {
		if (open === false) {
			setOpen(true);
		}
		handleClickAdministracion();
	}

	return (
		<Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<List style={{ backgroundColor: "#FFFFFF", paddingTop: 65 }}>
				{/* ADMINISTRACIÓN */}
				<ListItem button onClick={abrirAdministracionConAccesos}>
					<ListItemIcon>
						<Tune color="primary" />
					</ListItemIcon>
					<ListItemText style={{ color: "black" }}>
						Administración
					</ListItemText>
					{openAccesosAdministracion ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
				</ListItem>
				<Collapse in={openAccesosAdministracion && open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{/* Administración / Gestor de Activos*/}
						<ListItemComponent
							link={LINKS.GESTOR_DE_ACTIVOS}
							text={<Typography>Gestor de Activos</Typography>}
						/>

						{/* Administración / Sistema de Adquisiciones*/}
						<ListItemComponent
							link={LINKS.SISTEMA_DE_ADQUISICIONES}
							text={<Typography>Sistema de<br />Adquisiciones</Typography>}
						/>

						{/* Administración / Cydocs Comercial*/}
						<ListItemComponent
							link={LINKS.CYDOCS_COMERCIAL}
							text={<Typography>CyDocs Comercial</Typography>}
						/>

						{/* Administración / CyDocs Administración CyD*/}
						<ListItemComponent
							link={LINKS.CYDOCS_ADMINISTRACION_CYD}
							text={<Typography>CyDocs<br />Administración CyD</Typography>}
						/>

						{/* Administración / CyDocs Administración CDZ*/}
						<ListItemComponent
							link={LINKS.CYDOCS_ADMINISTRACION_CDZ}
							text={<Typography>CyDocs<br />Administración CDZ</Typography>}
						/>

						{/* Administración / Portafolio de Proyecto*/}
						<ListItemComponent
							link={LINKS.PORTAFOLIO}
							text={<Typography>Portafolio de<br />Contratos</Typography>}
						/>

						{/* Administración / Encuestas de Satisfacción*/}
						<ListItemComponent
							link={LINKS.ENCUESTAS}
							text={<Typography>Encuestas de<br />Satisfacción</Typography>}
						/>
					</List>
				</Collapse>

				{/* PROYECTOS */}
				<ListItem button onClick={abrirMenuConAccesos}>
					<ListItemIcon>
						<CardTravelTwoTone color="primary" />
					</ListItemIcon>
					<ListItemText style={{ color: "black" }}>
						Proyectos
					</ListItemText>
					{openAccesos ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
				</ListItem>
				<Collapse in={openAccesos && open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{/* Proyectos / Log Inspecciones*/}
						<ListItemComponent
							link={LINKS.LOG_INSPECCIONES}
							text={<Typography>Log Inspecciones</Typography>}
						/>

						{/* Proyectos / Log Bitácora*/}
						<ListItemComponent
							link={LINKS.LOG_BITACORA}
							text={<Typography>Log Bitácora</Typography>}
						/>

						{/* Proyectos / CyDocs Administración CyD*/}
						<ListItemComponent
							link={LINKS.GESTOR_DE_INFORMACION}
							text={<Typography>Gestor de<br />Información</Typography>}
						/>
					</List>
				</Collapse>
			</List>

			<Divider />

			{/* AJUSTES */}
			{permisos.includes(Permissions.AJUSTES_VER) && (
				<ListItem button component={Link} to={Routes.AJUSTES}>
					<ListItemIcon>
						<Settings color="inherit" />
					</ListItemIcon>
					<ListItemText>
						Ajustes
					</ListItemText>
				</ListItem>
			)}
		</Drawer>
	);
}

export function ListItemComponent({ link, text }) {
	let classes = useStyles();

	return (
		<ListItem button component="a" href={link} target="_blank" rel="noreferrer" className={classes.listItem}>
			<ListItemIcon>
				<FiberManualRecordTwoTone color="inherit" />
			</ListItemIcon>
			<ListItemText>
				{text}
			</ListItemText>
		</ListItem>
	);
}

const drawerWidth = 265;
const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		backgroundColor: "#FFFFFF",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		backgroundColor: "#FFFFFF",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: isMobile ? 0 : theme.spacing(7) + 1,
	},
	listItem: {
		paddingLeft: theme.spacing(4),
	}
}));

const LINKS = {
	GESTOR_DE_ACTIVOS: "https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://activos.cydocs.cl/",
	SISTEMA_DE_ADQUISICIONES: "https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://sda.cydocs.cl/",
	CYDOCS_COMERCIAL: "https://cydcomercial-2247d.firebaseapp.com/",
	CYDOCS_ADMINISTRACION_CYD: "https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://administracion.cydocs.cl/",
	CYDOCS_ADMINISTRACION_CDZ: "https://script.google.com/a/macros/cydingenieria.cl/s/AKfycbzBb4gDIQl7OgbTvHgzFLL_62o6RJYmlitD3blsKgJ-oOI6ILnO/exec",
	PORTAFOLIO: "https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://portafolio.cydocs.cl/",
	ENCUESTAS: "https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://encuestas.cydocs.cl/",
	LOG_INSPECCIONES: "https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://inspecciones.cydocs.cl/",
	LOG_BITACORA: "https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://bitacora.cydocs.cl/",
	GESTOR_DE_INFORMACION: "https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://sgi.cydocs.cl/",
}