import React, { Fragment, useContext, useState } from "react";
import MaterialTable from "../../../components/materialTable";
import DialogAdd from "./dialogAdd";
import DialogUpdate from "./dialogUpdate";
import DialogDelete from "./dialogDelete";
import { MainContext } from "../../../App";
import * as Permisos from "../../../constants/permissions";

function Page(props) {
  let {
    roles,
    is_loading,
    mutate_roles,
  } = props;

  const [IsOpen, SetIsOpen] = useState({});
  const { permisos } = useContext(MainContext);

  let columns = [
    {
      title: "Nombre",
      field: "nombre"
    }, {
      title: "Descripción",
      field: "descripcion"
    },
  ];

  let actions = [
    {
      icon: "edit",
      tooltip: "Actualizar",
      hidden: !permisos.includes(Permisos.ROLES_EDITAR),
      onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
    }, {
      icon: "delete",
      tooltip: "Eliminar (Requiere confirmación)",
      hidden: !permisos.includes(Permisos.ROLES_ELIMINAR),
      onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
    }, {
      icon: "add",
      tooltip: "Agregar",
      isFreeAction: true,
      hidden: !permisos.includes(Permisos.ROLES_AGREGAR),
      onClick: (event, row) => SetIsOpen({ tipo: "add", data: row }),
    }
  ];

  const handleCloseDialog = () => {
    SetIsOpen({});
  }

  return (
    <Fragment>
      {/* LISTADO DE ROLES */}
      <MaterialTable
        title="Roles"
        is_loading={is_loading}
        data={roles}
        columns={columns}
        actions={actions}
        export_excel={true}
        export_spreadsheet={true}
      />

      {/* DIALOG PARA AGREGAR */}
      {IsOpen.tipo === "add" && (
        <DialogAdd
          is_open={IsOpen.tipo === "add"}
          handle_close={handleCloseDialog}
          mutate_roles={mutate_roles}
        />
      )}

      {/* DIALOG PARA ACTUALIZAR */}
      {IsOpen.tipo === "update" && (
        <DialogUpdate
          is_open={IsOpen.tipo === "update"}
          data={IsOpen.data}
          handle_close={handleCloseDialog}
          mutate_roles={mutate_roles}
        />
      )}

      {/* DIALOG PARA ELIMINAR */}
      {IsOpen.tipo === "delete" && (
        <DialogDelete
          is_open={IsOpen.tipo === "delete"}
          data={IsOpen.data}
          handle_close={handleCloseDialog}
          mutate_roles={mutate_roles}
        />
      )}
    </Fragment>
  );
}

export default Page;