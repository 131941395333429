import { object, string } from "yup";

export const FormikInitialValues = {
	nombre: "",
	descripcion: "",
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	descripcion: string()
		.min(3, ({ min }) => `La descripción debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La descripción debe tener a lo más ${max} caracteres.`)
		.required("La descripción es requerida."),
});