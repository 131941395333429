import React, { useState } from "react";
import Page from "./page";

export default function HomeConteiner() {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Page
      open={open}
      activeStep={activeStep}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      handleNext={handleNext}
      handleBack={handleBack}
    />
  );
}
