import React from "react";
import Page from "./page";

function Index({ texto: Texto }) {
  return (
    <Page texto={Texto} />
  );
}

export default Index;
