import React from "react";
import Icon from "@mdi/react";
import { mdiPhone, mdiFormTextboxPassword, mdiGoogle, mdiCardAccountDetails, mdiCardAccountMail, mdiFirebase } from "@mdi/js";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar, Tooltip } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { GetFirebaseAuth, AddGoogleProvider, AddPhoneProvider, GetClaims, UpdateClaims } from "../../../services/request/usuarios/personas";
import { PROVIDERS } from "./utils";

export default function DialogFirebaseAuth(props) {
	let {
		is_open,
		data,
		handle_close,
		mutate_personas,
		handle_copy,
	} = props;

	const { data: firebaseAuth } = useSWR("firebase_auth", (key) => GetFirebaseAuth(data._id), { revalidateOnFocus: false });
	const { data: claims } = useSWR("claims", (key) => GetClaims(data._id), { revalidateOnFocus: false });

	const notistack = useSnackbar();

	/**
	 * Método encargado de agregar un provider de acceso.
	 * @param {*} tipoProvider Tipo de provider.
	 */
	const agregarProvider = async (tipoProvider) => {
		try {
			notistack.enqueueSnackbar("Agregando el nuevo acceso...", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
			});
			switch (tipoProvider) {
				case "google.com":
					await AddGoogleProvider(data._id);
					break;
				case "phone":
					await AddPhoneProvider(data._id);
					break;
				case "password":
					break;
				default:
					break;
			}
			notistack.closeSnackbar();
			notistack.enqueueSnackbar("Acceso agregado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar agregar el acceso.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} finally {
			mutate_personas();
			handleCloseDialog();
		}
	}

	/**
	 * Método encargado de habilitar o deshabilitar los Claims del usuario.
	 * @param {*} usuarioID ID del usuario.
	 */
	const actualizarClaims = async (usuarioID) => {
		try {
			notistack.enqueueSnackbar("Actualizando los Claims...", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
			});

			await UpdateClaims(usuarioID);

			notistack.closeSnackbar();
			notistack.enqueueSnackbar("Claims actualizados exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar actualizar los Claims.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} finally {
			mutate_personas();
			handleCloseDialog();
		}
	}

	/**
	 * Método encargado de verificar si el usuario está habilitado o deshabilitado.
	 * @returns TRUE: Usuario deshabilitado. FALSE: Usuario habilitado.
	 */
	function CheckDisabled() {
		if (firebaseAuth && firebaseAuth.disabled) {
			return true;
		}
		if (claims && claims.disabled) {
			return true;
		}
		return false;
	}

	/**
	 * Método encargado de cerrar el popup y cerrar los paneles.
	 */
	const handleCloseDialog = () => {
		handle_close();
	}

	return (
		<Dialog open={is_open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
			<DialogTitle>Información de Firebase Auth</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{`Cuentas y accesos de ${data.nombre_completo}.`}
				</DialogContentText>
				<List>
					{/* GSUITE */}
					<ListItem divider>
						<ListItemAvatar>
							<Avatar>
								<Icon path={mdiCardAccountDetails} size={1} />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary="Cuenta en GSuite"
							secondary={`Estado: ${data.gsuite_id ? "Datos en GSuite creados" : "Datos en GSuite no creados"}`}
						/>
						<ListItemSecondaryAction>
							<Tooltip title={data.gsuite_id ? "Copiar ID" : "Cuenta GSuite no asociada"} placement="left">
								<IconButton onClick={() => handle_copy("ID de GSuite", data.gsuite_id)} disabled={!data.gsuite_id} edge="end">
									<Icon path={mdiGoogle} size={1} color={data.gsuite_id ? "#00d230" : "#dc3545"} />
								</IconButton>
							</Tooltip>
						</ListItemSecondaryAction>
					</ListItem>

					{/* FIREBASE AUTH */}
					<ListItem divider>
						<ListItemAvatar>
							<Avatar>
								<Icon path={mdiCardAccountDetails} size={1} />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary="Cuenta en Firebase Auth"
							secondary={`Estado: ${data.usuario_id ? "Datos en Firebase Auth creados" : "Datos en Firebase Auth no creados"}`}
						/>
						<ListItemSecondaryAction>
							<Tooltip title={data.usuario_id ? "Copiar ID" : "Cuenta Firebase Auth no asociada"} placement="left">
								<IconButton onClick={() => handle_copy("ID de Firebase", data.usuario_id)} disabled={!data.usuario_id} edge="end">
									<Icon path={mdiFirebase} size={1} color={data.usuario_id ? "#00d230" : "#dc3545"} />
								</IconButton>
							</Tooltip>
						</ListItemSecondaryAction>
					</ListItem>

					{/* CLAIMS */}
					<ListItem divider>
						<ListItemAvatar>
							<Avatar>
								<Icon path={mdiCardAccountDetails} size={1} />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary="Claims"
							secondary={`Estado: ${CheckDisabled() ? "Claims deshabilitados" : "Claims habilitados"}`}
						/>
						<ListItemSecondaryAction>
							<Tooltip title={CheckDisabled() ? "Habilitar Claims" : "Deshabilitar Claims"} placement="left">
								<IconButton onClick={() => actualizarClaims(data._id)} disabled={!data.usuario_id} edge="end">
									<Icon path={mdiCardAccountMail} size={1} color={CheckDisabled() ? "#dc3545" : "#00d230"} />
								</IconButton>
							</Tooltip>
						</ListItemSecondaryAction>
					</ListItem>

					{/* PROVIDERS | GOOGLE, PASSWORD, PHONE */}
					{firebaseAuth && PROVIDERS.map((provider, index) => {
						let check = CheckHasProvider(firebaseAuth.providerData, provider.value);
						return (
							<ListItem divider key={`list_item_${index}`}>
								<ListItemAvatar>
									<Avatar>
										<Icon path={mdiCardAccountDetails} size={1} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={`Acceso por ${provider.label}`}
									secondary={`Estado: ${check ? "Habilitado" : "No Habilitado"}`}
								/>
								<ListItemSecondaryAction>
									<Tooltip title={check ? "" : "Generar acceso"} placement="left">
										<IconButton onClick={() => agregarProvider(provider.value)} disabled={check} edge="end">
											{GetIconByProvider(provider.value, check)}
										</IconButton>
									</Tooltip>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
			</DialogActions>
		</Dialog>
	);
}

/**
 * Método encargado de obtener el icono correspondiente por Provider.
 * @param {*} provider Identificador del Provider.
 * @returns Componente con el icono del Provider.
 */
function GetIconByProvider(provider, check = false) {
	switch (provider) {
		case "google.com":
			return <Icon path={mdiGoogle} size={1} color={check ? "#00d230" : "#dc3545"} />;
		case "password":
			return <Icon path={mdiFormTextboxPassword} size={1} color={check ? "#00d230" : "#dc3545"} />;
		case "phone":
			return <Icon path={mdiPhone} size={1} color={check ? "#00d230" : "#dc3545"} />;
		default:
			return null;
	}
}

/**
 * Método encargado de buscar dentro de una colección de providers uno en particular.
 * @param {*} providers Colección de providers.
 * @param {*} providerName Nombre del provider buscado.
 * @returns TRUE si existe el provider, caso contrario FALSE.
 */
function CheckHasProvider(providers, providerName) {
	let check = Array.from(providers).some(p => p.providerId === providerName);
	return check;
}