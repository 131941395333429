import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../../../constants/urls";
import { auth } from "../../firebase";

/**
 * Método encargado de obtener todas las personas.
 * @param {*} filtros Filtros opcionales para la consulta.
 * @returns Colección de personas.
 */
export async function GetPersonas(filtros = null) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas`;
		let response = await Axios.get(url, filtros ? { params: filtros } : null);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener todas las personas desde Talana.
 * @returns Colección de personas de Talana.
 */
export async function GetPersonasTalana() {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas-talana`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener todas las personas desde GSuite CyD.
 * @returns Colección de personas de GSuite CyD.
 */
export async function GetPersonasGSuiteCyD() {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas-gsuite/cyd`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener una persona, por su ID.
 * @param {*} personaID ID de la persona.
 * @returns Datos de la persona.
 */
export async function GetPersona(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener una persona, por su UID.
 * @param {*} personaUID UID de la persona.
 * @returns Datos de la persona.
 */
export async function GetByUID(personaUID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/uid/${personaUID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la información de Firebase Auth de una persona.
 * @param {*} personaID ID de la persona.
 * @returns Datos de Firebase Auth.
 */
export async function GetFirebaseAuth(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}/firebase-auth`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la información de Claims de una persona.
 * @param {*} personaID ID de la persona.
 * @returns Datos de Claims.
 */
export async function GetClaims(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}/claims`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar una persona.
 * @param {*} persona Datos de la persona.
 * @returns Dato agregado.
 */
export async function AddPersona(persona) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas`;
		let response = await Axios.post(url, persona);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar una persona, ya existente, a la plataforma de Proyectos.
 * @param {*} personaID ID de la persona.
 * @returns Dato agregado.
 */
export async function AddPersonaProyectos(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}/create-usuario-api-proyectos`;
		let response = await Axios.post(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de cargar la imagen de credencial.
 * @param {*} personaID ID de la persona.
 * @param {*} image Imagen de credencial.
 * @returns 
 */
export async function CargarImagenCredencial(personaID, image) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}/upload-photo-credential`;
		let response = await Axios.post(url, image);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar el provider de Google.
 * @param {*} personaID ID de la persona.
 * @returns Usuario Firebase Auth.
 */
export async function AddGoogleProvider(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}/google-provider`;
		let response = await Axios.post(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar el provider de número telefónico.
 * @param {*} personaID ID de la persona.
 * @returns Usuario Firebase Auth.
 */
export async function AddPhoneProvider(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}/phone-provider`;
		let response = await Axios.post(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de iniciar sesión de un usuario.
 * @returns Datos de inicio de sesión del usuario.
 */
export async function IniciarSession() {
	try {
		let tokenID = await auth.currentUser.getIdToken(true);
		let url = `${ADMINISTRACION_USUARIOS}/usuarios-plataforma/sesion`;
		let config = {
			headers: {
				authorization: `Bearer ${tokenID}`
			},
		};
		let response = await Axios.post(url, {}, config);
		return response.data ? response.data.data : null;
	} catch (error) {
		console.log(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar una persona.
 * @param {*} persona Datos de la persona.
 * @returns Dato actualizado.
 */
export async function UpdatePersona(persona) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${persona._id}`;
		let response = await Axios.put(url, persona);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar los Claims de una persona.
 * @param {*} personaID ID de la persona.
 * @returns Response.
 */
export async function UpdateClaims(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}/update-claims`;
		let response = await Axios.post(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar una persona.
 * @param {*} personaID ID de la persona.
 * @returns Respuesta de la eliminación.
 */
export async function DeletePersona(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar la sesión de una persona.
 * @param {*} personaID ID de la persona.
 * @returns Respuesta de la eliminación.
 */
export async function DeleteSession(personaID) {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas/${personaID}/refresh-token`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}