import { object, array } from "yup";
import * as Permissions from "../../../constants/permissions";

export const FormikInitialValues = {
	persona: null,
	permisos: [],
}

export const FormikValidationSchema = object().shape({
	persona: object()
		.nullable()
		.required("La persona es requerida."),
	permisos: array()
		.of(object())
		.min(1, ({ min }) => `Se requiere al menos ${min} permiso.`)
		.required("Los permisos son requeridos."),
});

export const PERMISOS = [
	{
		value: Permissions.USUARIOS_EXCEPCION_AGREGAR,
		label: "Permiso para agregar excepciones de usuarios",
		group: "Agregar",
	}, {
		value: Permissions.USUARIOS_EXCEPCION_EDITAR,
		label: "Permiso para editar excepciones de usuarios",
		group: "Editar",
	}, {
		value: Permissions.USUARIOS_EXCEPCION_ELIMINAR,
		label: "Permiso para eliminar excepciones de usuarios",
		group: "Eliminar",
	}, {
		value: Permissions.USUARIOS_EXCEPCION_VER,
		label: "Permiso para ver excepciones de usuarios",
		group: "Ver",
	}, {
		value: Permissions.USUARIOS_PLATAFORMA_AGREGAR,
		label: "Permiso para agregar usuarios de plataforma",
		group: "Agregar",
	}, {
		value: Permissions.USUARIOS_PLATAFORMA_EDITAR,
		label: "Permiso para editar usuarios de plataforma",
		group: "Editar",
	}, {
		value: Permissions.USUARIOS_PLATAFORMA_ELIMINAR,
		label: "Permiso para eliminar usuarios de plataforma",
		group: "Eliminar",
	}, {
		value: Permissions.USUARIOS_PLATAFORMA_VER,
		label: "Permiso para ver usuarios de plataforma",
		group: "Ver",
	}, {
		value: Permissions.AJUSTES_VER,
		label: "Permiso para ver el módulo de ajustes",
		group: "Ver",
	}, {
		value: Permissions.APLICACIONES_AGREGAR,
		label: "Permiso para agregar aplicaciones",
		group: "Agregar",
	}, {
		value: Permissions.APLICACIONES_EDITAR,
		label: "Permiso para editar aplicaciones",
		group: "Editar",
	}, {
		value: Permissions.APLICACIONES_ELIMINAR,
		label: "Permiso para eliminar aplicaciones",
		group: "Eliminar",
	}, {
		value: Permissions.APLICACIONES_VER,
		label: "Permiso para ver aplicaciones",
		group: "Ver",
	}, {
		value: Permissions.INSPECCIONES_VER,
		label: "Permiso para ver inspecciones",
		group: "Ver",
	}, {
		value: Permissions.PERSONAS_AGREGAR,
		label: "Permiso para agregar personas",
		group: "Agregar",
	}, {
		value: Permissions.PERSONAS_EDITAR,
		label: "Permiso para editar personas",
		group: "Editar",
	}, {
		value: Permissions.PERSONAS_ELIMINAR,
		label: "Permiso para eliminar personas",
		group: "Eliminar",
	}, {
		value: Permissions.PERSONAS_VER,
		label: "Permiso para ver personas",
		group: "Ver",
	}, {
		value: Permissions.PERSONAS_VER_CREDENCIAL,
		label: "Permiso para ver las credenciales",
		group: "Ver",
	}, {
		value: Permissions.PERSONAS_TALANA_VER,
		label: "Permiso para ver personas de Talana",
		group: "Ver",
	}, {
		value: Permissions.ROLES_AGREGAR,
		label: "Permiso para agregar roles",
		group: "Agregar",
	}, {
		value: Permissions.ROLES_EDITAR,
		label: "Permiso para editar roles",
		group: "Editar",
	}, {
		value: Permissions.ROLES_ELIMINAR,
		label: "Permiso para eliminar roles",
		group: "Eliminar",
	}, {
		value: Permissions.ROLES_VER,
		label: "Permiso para ver roles",
		group: "Ver",
	}, {
		value: Permissions.USUARIOS_GENERADOR_QR,
		label: "Permiso para generar códigos QR",
		group: "Ver",
	}
];