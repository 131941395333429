import React from "react";
import {
  Backdrop,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GalleryCarousel from "./GalleryCarousel";
import GaleriaBanner from "./GaleriaBanner";
import CardAppDestacada from "../../components/cardAppDestacada";
import CardAppDestacadaDescargable from "../../components/cardAppDestacadaDescargable";
import CardApp from "../../components/cardApp";
import CardAppAction from "../../components/cardAppAction";

function Page(props) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <Box>
      {/* BANNER */}
      <GaleriaBanner popUp={handleOpenModal} />

      {/* APPS DESTACADAS */}
      <Grid container spacing={5}>
        {/* TITULO DE SECCION */}
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h4" style={{ paddingLeft: 20 }}>
            DESTACADOS
          </Typography>
        </Grid>

        {/* APPCYDOCS */}
        <Grid item lg={4} md={6} sm={12}>
          <CardAppDestacadaDescargable
            action={handleOpenModal}
            link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/CydocsApp2.png"
            text="App CyDocs"
            text_more="En esta aplicación convergen todos los módulos habilitados con versión móvil como: Inspecciones de terreno, bitácora de proyectos, repositorio de información de contratos, entre otros."
            text_button="Descargar"
            on_android={true}
            on_apple={true}
            on_computer={false}
          />
        </Grid>

        {/* GESTOR DE ACTIVOS */}
        <Grid item lg={4} md={6} sm={12}>
          <CardAppDestacada
            link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://activos.cydocs.cl/"
            link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Activos.png"
            text="Gestor de Activos"
            text_more="Módulo destinado a conocer y gestionar los activos asociados a personas, contratos y gerencias."
            text_button="Acceder"
          />
        </Grid>

        {/* PORTAFOLIO */}
        <Grid item lg={4} md={6} sm={12}>
          <CardAppDestacada
            link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://portafolio.cydocs.cl/"
            link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Portafolio.png"
            text="Portafolio"
            text_more="Encuentra la información base de cada contrato como, el responsable, código, nombre, encuestas de satisfacción de clientes y más."
            text_button="Acceder"
          />
        </Grid>
      </Grid>

      {/* OTRA APPS */}
      <Grid container spacing={5} style={{ paddingTop: "30px" }}>
        {/* COLUMNA IZQUIERDA */}
        <Grid item xl={6} lg={6} md={12}>
          {/* TITULO DE SECCION */}
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Typography variant="h5">CyDocs ADMINISTRACIÓN</Typography>
          </Grid>

          {/* LISTADO DE APLICACIONES */}
          <Grid container spacing={2}>
            {/* Gestor de Activos */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://activos.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Activos.png"
                text="Sistema Gestor de Activos"
                text_popup="Módulo destinado a conocer y gestionar los Activos de CyD"
              />
            </Grid>

            {/* Sistema de adquisiciones */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://sda.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Adquisiciones.png"
                text="Sistema de Adquisiciones"
                text_popup="Plataforma para solicitar y gestionar recursos"
              />
            </Grid>

            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://gestor-pagos.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Adquisiciones.png"
                text="Sistema Gestor de Pagos"
                text_popup="Plataforma para gestionar pagos de facturas"
              />
            </Grid>

            {/* Sistema de Operaciones */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://operaciones.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/desarrollo-de-aplicaciones.png"
                text="Plataforma de Operaciones"
                text_popup="Plataforma que permite gestionar la información de los contratos y la facturación"
              />
            </Grid>

            {/* Cydocs Admin Cyd */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://administracion.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/AdminCYD.png"
                text="CyDocs Admin. CyD"
                text_popup="Módulo gestor de ventas (memos de facturación) y boletas de garantía para CyD"
              />
            </Grid>

            {/* Cydocs admin CDZ */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://script.google.com/a/macros/cydingenieria.cl/s/AKfycbzBb4gDIQl7OgbTvHgzFLL_62o6RJYmlitD3blsKgJ-oOI6ILnO/exec"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/AdminCDZ.png"
                text="CyDocs Admin. CDZ"
                text_popup="Módulo gestor de ventas (memos de facturación) y boletas de garantía para CyD Tecnología"
              />
            </Grid>

            {/* Cydocs admin C3 */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://script.google.com/macros/s/AKfycbz52XtdcmqHZ28tV1nsj-J5q_wVEQ_fb_A9tUfN_UrA9udASWQhhpLloU1wjynDXrAA/exec"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/AdminCYD.png"
                text="CyDocs Admin. C3"
                text_popup="Módulo de Gestor de ventas y Gestor de Personal"
              />
            </Grid>

            {/* Cydocs Comercial */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://crm.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Comercial.png"
                text="CRM CyD"
                text_popup="Gestor de oportunidades, propuestas y experiencia de CyD"
              />
            </Grid>

            {/* Gestor de Contrataciones */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://contrataciones.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/gestor_contratacion.png"
                text="Gestor de Contrataciones"
                text_popup="Plataforma para gestionar la contratación de personas"
              />
            </Grid>

            {/* Portafolio de Contratos */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://portafolio.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Portafolio.png"
                text="Portafolio de Contratos"
                text_popup="Listado de contratos vigentes de CyD"
              />
            </Grid>

            {/* Encuesta de Satisfacción */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://encuestas.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Encuestas.png"
                text="Encuesta de Satisfacción"
                text_popup="Plataforma para envío y análisis de encuestas de satisfacción de clientes"
              />
            </Grid>

            {/* Análisis Seguro de Trabajo (AST) */}
            {/* <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://ast.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Encuestas.png"
                text="Análisis Seguro del Trabajo (AST)"
                text_popup="Plataforma para la generación y análisis de procesos que permiten identificar peligros potenciales"
              />
            </Grid> */}

            {/* Status CyDocs */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://status.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/Encuestas.png"
                text="Status CyDocs"
                text_popup="Plataforma para monitorear el estado de las plataformas de CyDocs"
              />
            </Grid>

            {/* Evaluación de Desempeño */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://evaluacion.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/eval_desempeno.png"
                text="Evaluación de Desempeño"
                text_popup="Plataforma para gestionar las evaluaciones de desempeño de CyD"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* COLUMNA DERECHA */}
        <Grid item xl={6} lg={6} md={12}>
          {/* TITULO DE SECCION */}
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Typography variant="h5">CyDocs PROYECTOS</Typography>
          </Grid>

          {/* LISTADO DE APLICACIONES */}
          <Grid container spacing={2}>
            {/* App CyDocs */}
            <Grid item md={4} sm={6}>
              <CardAppAction
                action={handleOpenModal}
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/CydocsApp2.png"
                text="App CyDocs"
                text_popup="Aplicación para emitir reportes desde terreno como, inspecciones, bitácora u obtener información del proyectos"
                button_color="secondary"
              />
            </Grid>

            {/* Log Inspecciones */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://inspecciones.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Proyectos/Inspecciones.png"
                text="Log Inspecciones"
                text_popup="Repositorio de la información emitida desde la aplicación móvil CyDocs"
                button_color="secondary"
              />
            </Grid>

            {/* Log Bitácora */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://bitacora.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Proyectos/Bitacora.png"
                text="Log Bitácora"
                text_popup="Medición de productividad de proyectos"
                button_color="secondary"
              />
            </Grid>

            {/* Gestor de Información */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://documentos.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Proyectos/Informacion.png"
                text="Gestor de Información"
                text_popup="Repositorio de información web y móvil (Control Documental)"
                button_color="secondary"
              />
            </Grid>

            {/* Configurador de Proyectos */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://configurador-proyecto.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Proyectos/Informacion.png"
                text="Configurador de Proyectos"
                text_popup="Plataforma que permite administrar contratos, presupuestos y programas"
                button_color="secondary"
              />
            </Grid>

            {/* Nuevo Sitio de Inspecciones */}
            <Grid item md={4} sm={6}>
              <CardApp
                link_redirect="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://inspecciones-v2.cydocs.cl/"
                link_icon="https://storage.googleapis.com/mi-cyd/CyDocs/Icons/Administraci%C3%B3n/eval_desempeno.png"
                text="CyDocs Inspecciones"
                text_popup="Plataforma WEB para gestionar las Inspecciones de Terreno"
                button_color="secondary"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        align="center"
        className={classes.modalStyle1}
        open={openModal}
        overflow="scroll"
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Paper style={{ margin: "50px", width: "70%" }}>
            <GalleryCarousel />
          </Paper>
        </Fade>
      </Modal>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  botonProyectos: {
    borderRadius: "15px",
  },
  cardDestacados: {
    marginLeft: "30px",
    marginRight: "30px",
    marginBottom: "30px",
  },
  cardDestacadosActions: {
    paddingTop: "20px",
    paddingLeft: "20px",
    color: "gray",
  },
  cardDestacadosContent: {},
  cardContenido: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
  },
  cardAdmin: {
    borderRadius: "20px",
    minHeight: 220,
  },
  cardAdminActions: {
    paddingLeft: "20px",
    paddingTop: "10px",
    color: "gray",
  },
  cardContenidoAdmin: {
    padding: "10px",
  },
  cardTituloLinks: {
    paddingLeft: "20px",
    height: "50px",
    display: "flex",
    alignItems: "center",
  },
  image: {
    height: "80px",
    paddingBottom: "5px",
  },
}));

export default Page;
