import React, { useState, createContext, useEffect } from "react";
import * as PERMISSIONS from "./constants/permissions";
import * as ROUTES from "./constants/routes";
import * as URLS from "./constants/urls";
import { Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import { IniciarSession, GetPersona } from "./services/request/usuarios/personas";
import { GetGerencia } from "./services/request/portafolio/gerencias";
import { makeStyles } from "@material-ui/core/styles";
import { auth, onAuthStateChange } from "./services/firebase";
import Error401 from "./container/401";
import HomeConteiner from "./container/home";
import Ajustes from "./container/ajustes";
import AppBar from "./components/appBar";
import { redirectToAccount } from "./services/auth";
import Loading from './components/page_loading'
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  footer: {
    marginTop: "1rem",
    padding: "1rem",
    position: "fixed",
    color: theme.palette.text.secondary,
    bottom: 0,
    left: 0,
    width: "100%",
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
}));

const login = async () => {
  let currenToken = getQueryVariable("csrfToken");
  if (!currenToken) {
    currenToken = localStorage.getItem("csrfToken");
  }
  localStorage.setItem("csrfToken", currenToken);
  if (currenToken && currenToken !== "null" && currenToken !== "undefined") {
    localStorage.setItem("contrato", "");
    localStorage.setItem("proyecto", "");
    await axios
      .post(
        `${URLS.REDIRECT_BASE}/verifySessionCookie`,
        {},
        {
          withCredentials: true,
          crossDomain: true,
          params: {
            _csrf: currenToken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.token) {
          auth.signInWithCustomToken(response.data.token);
        }
      })
      .catch((error) => {
        console.log(error);
        redirectToAccount()
      });
  } else {
    redirectToAccount()
  }
};

//TODO ver react-router-dom los hooks
const getQueryVariable = function (variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

export const MainContext = createContext({
  usuarioSesion: null,
  accessError: false,
  setAccessError: () => { },
  permisos: [],
  setPermisos: () => { },
});

export default function App() {
  const classes = useStyles();

  const [usuarioSesion, setUsuarioSesion] = useState(null);
  const [permisos, setPermisos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [accessError, setAccessError] = useState(true);

  const checkSession = (isLogged) => {
    try {
      if (isLogged && auth.currentUser) {
        const user = auth.currentUser;
        ObtenerDatosUsuario(user);
      } else {
        login();
      }
    } catch (error) {
      console.log(error);
      // setAccessError(true);
    }
  };

  /**
   * Método encargado de cargar la información del usuario, TAGs, gerencia.
   * @param {*} user 
   */
  async function ObtenerDatosUsuario(user) {
    try {
      let usuario = await IniciarSession();
      let persona = await GetPersona(usuario.ref);
      let gerencia = await GetGerencia(persona.gerencia_ref);

      let data = {
        photoUrl: user.photoURL,
        nombre: user.displayName,
        email: user.email,
        ref: usuario.ref,
        uid: persona.auth_id,
        gerencia: {
          _id: gerencia._id,
          nombre: gerencia.nombre,
          sigla: gerencia.sigla,
        },
      }
      setUsuarioSesion(data);
      if (usuario.permisos) {
        setPermisos(usuario.permisos);
      } else {
        setPermisos([]);
      }
      setIsLoading(false);
      setAccessError(false);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChange(checkSession);
    return () => {
      unsubscribe();
    };
  }, []);

  const privateRoute = ({
    component: Component,
    permission,
    path,
    ...rest
  }) => {
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          if (accessError) {
            return <Redirect to="/accessError" />;
          }
          if (!accessError && path === ROUTES.ACCESS_ERROR) {
            return <Redirect to="/" />;
          }
          if (permission && usuarioSesion && !permisos.includes(permission)) {
            return <Redirect to="/" />;
          }
          return (
            <Component {...props} />
          );
        }}
      />
    );
  };

  const mainAppNew = (
    <React.Fragment>
      <MainContext.Provider
        value={{ usuarioSesion, accessError, setAccessError, permisos, setPermisos }}
      >
        <div style={{ display: "flex", paddingTop: "80px", backgroundColor: "#E9E9E9", overflow: "hidden" }} className={classes.root}>
          <AppBar nombreModulo=" Mi CyD" />
          <Container disableGutters fixed maxWidth="xl" style={{ width: "90%", paddingBottom: 20 }}>
            <Switch>
              <Route
                exact={true}
                path={ROUTES.HOME}
                component={() => <HomeConteiner />}
              />
              {privateRoute({
                component: Ajustes,
                permission: PERMISSIONS.AJUSTES_VER,
                exact: true,
                path: ROUTES.AJUSTES
              })}
              <Route
                exact={true}
                path={ROUTES.ACCESS_ERROR}
                component={() => <Error401 />}
              />
            </Switch>
          </Container>
        </div>
      </MainContext.Provider>
    </React.Fragment>
  );

  if (usuarioSesion && permisos && !isLoading && !accessError) {
    return mainAppNew;
  } else {
    return <Loading />
  }
}