import React, { useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { GetAplicaciones } from "../../../services/request/usuarios/aplicaciones";

function Index(props) {
  const { data, isValidating, mutate, error } = useSWR("aplicaciones", GetAplicaciones, { revalidateOnFocus: false });
  const notistack = useSnackbar();

  useEffect(() => {
    if (error) {
      notistack.enqueueSnackbar("Error al intentar cargar los datos de las aplicaciones.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
      });
    }
  }, [notistack, error]);

  return (
    <Page
      aplicaciones={data}
      is_loading={isValidating}
      mutate_aplicaciones={mutate}
    />
  );
}

export default Index;