import { object, string } from "yup";

export const FormikInitialValues = {
	nombre: "",
	descripcion: "",
	nombre_custom: "",
	contrato: null,
	tipo_app: null,
	tipo: null,
	categoria: null,
	logo: "",
	link_ayuda: "",
	link_carpeta_pdfs: "",
	link_carpeta_imagenes: "",
	link_template: "",
	link_template_cierre: "",
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	descripcion: string()
		.min(3, ({ min }) => `La descripción debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La descripción debe tener a lo más ${max} caracteres.`)
		.required("La descripción es requerida."),
	nombre_custom: string()
		.min(3, ({ min }) => `El nombre alternativo debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre alternativo debe tener a lo más ${max} caracteres.`)
		.required("El nombre alternativo es requerido."),
	contrato: object()
		.nullable()
		.required("El contrato es requerido."),
	tipo_app: object()
		.nullable()
		.required("El tipo de aplicación es requerido."),
	tipo: object()
		.nullable()
		.required("El tipo es requerido."),
	categoria: object()
		.nullable()
		.required("La categoría es requerida."),
	logo: string()
		.url("Formato de URL incorrecto")
		.required("El logo es requerido."),
	link_ayuda: string()
		.url("Formato de URL incorrecto")
		.optional(),
	link_carpeta_pdfs: string()
		.url("Formato de URL incorrecto")
		.optional(),
	link_carpeta_imagenes: string()
		.url("Formato de URL incorrecto")
		.optional(),
	link_template: string()
		.url("Formato de URL incorrecto")
		.optional(),
	link_template_cierre: string()
		.url("Formato de URL incorrecto")
		.optional(),
});

export const TIPOS_APLICACION = [
	{
		value: "1",
		label: "Inspecciones",
	}, {
		value: "2",
		label: "SGI",
	}, {
		value: "3",
		label: "Bitácora",
	}, {
		value: "4",
		label: "Libro de Obra",
	}, {
		value: "5",
		label: "POD",
	}, {
		value: "6",
		label: "ART",
	}
];

export const TIPOS = [
	{
		value: 1,
		label: "Móvil"
	}, {
		value: 2,
		label: "Web"
	}, {
		value: 3,
		label: "Sin Tipo"
	},
];

export const CATEGORIAS = [
	{
		value: 1,
		label: "Administración"
	}, {
		value: 2,
		label: "Proyecto"
	}, {
		value: 3,
		label: "Sin Categoría"
	},
];