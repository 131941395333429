import React, { StrictMode } from "react";
import Page from "./page";

function Index(props) {

	return (
		<StrictMode>
			<Page
			/>
		</StrictMode>
	);
}

export default Index;