import { object, string, boolean, array } from "yup";

const REGEX_RUN = /^(\d{7}|\d{8})-[0-9kK]$/;
const REGEX_TELEFONO = /^((\+56)?(9))?\d{8}$/;

export const FormikInitialValues = {
	run: "",
	nombre: "",
	apellido_paterno: "",
	apellido_materno: "",
	cargo: "",
	titulo: "",
	photo: "",
	contacto: {
		telefono: "",
		telefono_alternativo: "",
		telefono_personal: "",
		email: "",
		email_alternativo: "",
		email_personal: "",
	},
	password: "",
	usuario_id: "",
	auth_id: "",
	gsuite_id: "",
	gerencia: null,
	centro_costo: null,
	contrato: null,
	rol: null,
	contratos_visibles_ref: [],
	proyectos_visibles_ref: [],
	aplicaciones_visibles_ref: [],
	is_responsable: false,
	is_habilitado: false,
	is_cliente: false,
	is_externo: false,
	is_gsuite: false,
	is_talana: false,
}

export const FormikValidationSchema = object().shape({
	run: string()
		.min(9, ({ min }) => `El RUN debe tener al menos ${min} caracteres.`)
		.max(10, ({ max }) => `El RUN debe tener a lo más ${max} caracteres.`)
		.matches(REGEX_RUN, { message: "El RUN debe tener el formato \"12345678-9\"." })
		.required("El RUN es requerido."),
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	apellido_paterno: string()
		.min(3, ({ min }) => `El apellido paterno debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El apellido paterno debe tener a lo más ${max} caracteres.`)
		.required("El apellido paterno es requerido."),
	apellido_materno: string()
		.min(3, ({ min }) => `El apellido materno debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El apellido materno debe tener a lo más ${max} caracteres.`)
		.required("El apellido materno es requerido."),
	cargo: string()
		.min(3, ({ min }) => `El cargo debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El cargo debe tener a lo más ${max} caracteres.`)
		.required("El cargo es requerido."),
	titulo: string()
		.min(3, ({ min }) => `El título debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El título debe tener a lo más ${max} caracteres.`)
		.optional(),
	contacto: object()
		.shape({
			email: string()
				.email("El correo debe tener el formato \"usuario@dominio\".")
				.required("El correo es requerido."),
			email_alternativo: string()
				.email("El correo alternativo debe tener el formato \"usuario@dominio\".")
				.optional(),
			email_personal: string()
				.email("El correo personal debe tener el formato \"usuario@dominio\".")
				.optional(),
			telefono: string()
				.min(8, ({ min }) => `El telfoy debe tener al menos ${min} caracteres.`)
				.max(12, ({ max }) => `El telfoy debe tener a lo más ${max} caracteres.`)
				.matches(REGEX_TELEFONO, { message: "El teléfono debe tener el formato \"[+56][9]87654321\"." })
				.optional(),
			telefono_alternativo: string()
				.min(8, ({ min }) => `El telfoy alternativo debe tener al menos ${min} caracteres.`)
				.max(12, ({ max }) => `El telfoy alternativo debe tener a lo más ${max} caracteres.`)
				.matches(REGEX_TELEFONO, { message: "El teléfono alternativo debe tener el formato \"[+56][9]87654321\"." })
				.optional(),
			telefono_personal: string()
				.min(8, ({ min }) => `El telfoy personal debe tener al menos ${min} caracteres.`)
				.max(12, ({ max }) => `El telfoy personal debe tener a lo más ${max} caracteres.`)
				.matches(REGEX_TELEFONO, { message: "El teléfono personal debe tener el formato \"[+56][9]87654321\"." })
				.optional(),
		})
		.required("La información de contacto es requerida."),
	gerencia: object()
		.nullable()
		.required("La gerencia es requerida."),
	centro_costo: object()
		.nullable()
		.required("El centro de costos de la persona es requerido."),
	contrato: object()
		.nullable()
		.required("El contrato de la persona es requerido."),
	rol: object()
		.nullable()
		.required("El rol es requerido."),
	contratos_visibles_ref: array()
		.of(string())
		.optional(),
	proyectos_visibles_ref: array()
		.of(string())
		.optional(),
	aplicaciones_visibles_ref: array()
		.of(string())
		.optional(),
	is_habilitado: boolean()
		.optional(),
	is_responsable: boolean()
		.optional(),
	is_cliente: boolean()
		.optional(),
	is_externo: boolean()
		.optional(),
	is_gsuite: boolean()
		.optional(),
	is_talana: boolean()
		.optional(),
});

export const FILTROS = [
	{
		value: "is_habilitado",
		label: "¿Habilitado?",
	}, {
		value: "is_responsable",
		label: "¿Responsable?"
	}, {
		value: "is_cliente",
		label: "¿Cliente?",
	}, {
		value: "is_externo",
		label: "¿Externo?",
	}, {
		value: "is_gsuite",
		label: "¿GSuite?",
	}, {
		value: "is_talana",
		label: "¿Talana?",
	}, {
		value: "is_app_inspecciones",
		label: "¿Tiene App de Inspecciones?",
	}, {
		value: "is_app_libro_obra",
		label: "¿Tiene App de Libre de Obra?",
	}, {
		value: "is_app_bitacora",
		label: "¿Tiene App de Bitácora?",
	}, {
		value: "is_app_sgi",
		label: "¿Tiene App de SGI?",
	}, {
		value: "is_app_pod",
		label: "¿Tiene App de POD?",
	}, {
		value: "is_app_art",
		label: "¿Tiene App de ART?",
	},
];

export const PROVIDERS = [
	{
		value: "google.com",
		label: "Google",
	}, {
		value: "password",
		label: "Contraseña",
	}, {
		value: "phone",
		label: "Teléfono",
	},
];