import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, IconButton, Switch, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { DeletePersona, DeleteSession } from "../../../services/request/usuarios/personas";

export default function DialogDelete(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_personas,
	} = props;

	const [CheckRefreshToken, SetCheckRefreshToken] = useState(false);
	const [CheckPersona, SetCheckPersona] = useState(false);

	const notistack = useSnackbar();

	/**
	 * Método encargado de eliminar todos los datos de un usuario.
	 */
	const handleDeletePersona = async () => {
		try {
			notistack.enqueueSnackbar("Eliminando la persona...", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
			});
			await DeletePersona(data._id);
			notistack.closeSnackbar();
			notistack.enqueueSnackbar("Aplicación eliminada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar la persona.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} finally {
			mutate_personas();
			handleClose();
		}
	}

	/**
	 * Método encargado de eliminar la sesión del usuario.
	 * Deberá logearse nuevamente.
	 */
	const handleDeleteSession = async () => {
		try {
			await DeleteSession(data._id);
			notistack.enqueueSnackbar("Sesión eliminada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar la sesión de la persona.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} finally {
			mutate_personas();
			handleClose();
		}
	}

	/**
	 * Método encargado de confirmar la eliminación de datos.
	 * @param {*} event Evento gatillado.
	 * @param {*} checked Valor de la verificación.
	 */
	const handleToggle = (event, checked) => {
		SetCheckRefreshToken(false);
		SetCheckPersona(false);
		switch (event.currentTarget.name) {
			case "check_refresh_token":
				SetCheckRefreshToken(checked);
				break;
			case "check_persona":
				SetCheckPersona(checked);
				break;
			default:
				break;
		}
	}

	/**
	 * Método encargado de cerrar el popup y reiniciar los States.
	 */
	const handleClose = () => {
		handle_close();
		SetCheckRefreshToken(false);
		SetCheckPersona(false);
	}

	return (
		<Dialog open={is_open} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>Eliminar</DialogTitle>
			<DialogContent>
				<DialogContentText>{`Debe confirmar la eliminación de datos de ${data?.nombre_completo}.`}</DialogContentText>

				{/* ELIMINACIÓN DE REFRESH TOKEN */}
				<Box display="flex" p={2}>
					<Box flexGrow={1}>
						<Typography>Eliminar Refresh Token</Typography>
						<Typography variant="caption">Terminará la sesión del usuario.</Typography>
					</Box>
					<Box pr={2}>
						<FormControlLabel
							control={<Switch checked={CheckRefreshToken} onChange={handleToggle} color="primary" name="check_refresh_token" />}
							label="Confirmación"
							labelPlacement="start"
						/>
					</Box>
					<Button onClick={handleDeleteSession} disabled={!CheckRefreshToken} variant="contained" color="primary">Eliminar</Button>
				</Box>

				<Divider />

				{/* ELIMINACIÓN TOTAL DEL USUARIO */}
				<Box display="flex" p={2}>
					<Box flexGrow={1}>
						<Typography>Eliminar Persona</Typography>
						<Typography variant="caption">Elimina todos los datos del usuario.</Typography>
					</Box>
					<Box pr={2}>
						<FormControlLabel
							control={<Switch checked={CheckPersona} onChange={handleToggle} color="primary" name="check_persona" />}
							label="Confirmación"
							labelPlacement="start"
						/>
					</Box>
					<Button onClick={handleDeletePersona} disabled={!CheckPersona} variant="contained" color="primary">Eliminar</Button>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">Cancelar</Button>
			</DialogActions>
		</Dialog>
	);
}