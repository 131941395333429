import React, { Fragment, useRef, useState } from "react";
import { Box, Button, Grid, IconButton, Paper, TextField, Tooltip } from "@material-ui/core";
import { QRCodeCanvas } from 'qrcode.react';
import { useSnackbar } from "notistack";
import { Close } from "@material-ui/icons";


function Page(props) {
	const notistack = useSnackbar();
	const [link, setLink] = useState(null);
	const [error, setError] = useState("");

	const agregarLink = (value) => {

		//Verificar si el texto ingresado es un link
		var regex = /^(ftp|http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
		//test link
		if (regex.test(document.getElementById("link_textfield").value)) {
			//Si el texto es un link, se limpia el texto del helperText 
			setError("")
			//Se guarda el texto del Textfield
			setLink(document.getElementById("link_textfield").value);
			notistack.enqueueSnackbar("Código QR generado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} else {
			//Se despliega el texto del helpertext del textfield cuando no es link
			setError("Enlace no válido")
		}
	};

	const canvasRef = useRef();
	const descargarQR = () => {
		const canvas = canvasRef.current.children[0]?.children[0];
		const pngFile = canvas.toDataURL("image/png");

		const downloadLink = document.createElement("a");
		downloadLink.download = "Mi QR Generado";
		downloadLink.href = `${pngFile}`;
		downloadLink.click();
		notistack.enqueueSnackbar("Código QR descargado exitosamente.", {
			variant: "success",
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
		});
	};


	return (
		<Fragment>
			<Paper style={{ padding: "10px" }}>
				<Paper variant="outlined" style={{ height: "100%", margin: "20px" }}>
					<Grid container>
						<Grid item xs={12} style={{ marginBottom: "20px" }}>
							<Box display="flex" style={{ height: "100%", alignItems: "center", justifyContent: "center", width: "100%" }}>
								<Paper variant="outlined" style={{ padding: "10px", margin: "10px", borderColor: "#731f1f" }}>
									<strong>Instrucciones:</strong> <br />
									<strong>1.</strong> Escribe o pega el link que deseas convertir en el código QR. Asegúrate de que la información ingresada sea correcta y completa. Verifica que no haya errores tipográficos o espacios adicionales.<br />
									<strong>2.</strong> Una vez que hayas ingresado el link. Haz clic en el botón "Generar" para iniciar el proceso de generación del código QR.<br />
									<strong>3.</strong> Una vez generado, si deseas descargar el código QR, puedes hacer clic sobre él para guardarlo en tu dispositivo.<br />
								</Paper>

							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box display="flex" style={{ height: "100%", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "10px" }}>
								<Grid container>

									<Grid item xs={12}>
										<Box display="flex" style={{ height: "100%", alignItems: "center", justifyContent: "center", width: "100%" }}>
											<TextField
												id="link_textfield"
												variant="outlined"
												label="Link"
												placeholder="http://www.google.cl"
												style={{ width: "90%" }}
												helperText={error}
												error={error !== ""}
											/>

										</Box>
									</Grid>
									<Grid item xs={12} style={{ marginBottom: "20px" }}>
										<Box display="flex" style={{ height: "100%", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "10px" }}>
											<Button variant="contained" color="primary" onClick={agregarLink}>
												Generar
											</Button>

										</Box>
									</Grid>

								</Grid>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box display="flex" style={{ alignItems: "center", justifyContent: "center" }}>

								{link ?
									<div ref={canvasRef}>
										<Tooltip title="Haz click en el código QR para descargarlo" placement="top">
											<div style={{ cursor: "pointer" }}>
												<QRCodeCanvas
													value={link}
													size={250}
													bgColor={"#ffffff"}
													fgColor={"#000000"}
													level={"L"}
													renderAs={"svg"}
													includeMargin={true}
													onClick={descargarQR}
												/>
											</div>
										</Tooltip>
									</div>
									: <div />
								}
							</Box>
						</Grid>

					</Grid>
				</Paper>
			</Paper>
		</Fragment >
	);
}

export default Page;