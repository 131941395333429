import React from "react";
import Moment from "moment";
import { Tooltip, Typography } from "@material-ui/core";
import MaterialTable from "../../../components/materialTable";

function Page(props) {
	const {
		personas,
		is_loading,
		handle_copy,
	} = props;

	if (personas && personas.data) {
		personas.data = personas.data.map(p => ({
			...p,
			nombre_completo: `${p.nombre} ${p.apellido_paterno} ${p.apellido_materno}`,
			fecha_nacimiento_format: Moment(p.fecha_nacimiento).utc().format("DD/MM/YYYY"),
			fecha_contratacion_format: Moment(p.fecha_contratacion).utc().format("DD/MM/YYYY"),
		}));
	}

	let columns = [
		{
			title: "RUN",
			field: "rut",
			removable: false,
			cellStyle: {
				whiteSpace: "nowrap"
			},
		}, {
			title: "Nombre",
			field: "nombre_completo",
			removable: false,
		}, {
			title: "Fecha Nacimiento",
			field: "fecha_nacimiento_format",
		}, {
			title: "Email",
			field: "email",
			removable: false,
			render: (row) => (
				<Tooltip title="Click para copiar" onClick={() => handle_copy("Email", row.email)}>
					<Typography>{row.email}</Typography>
				</Tooltip>
			),
		}, {
			title: "Empresa",
			field: "empresa",
		}, {
			title: "Cargo",
			field: "cargo",
		}, {
			title: "Fecha Contratación",
			field: "fecha_contratacion_format",
		}, {
			title: "Nombre Proyecto",
			field: "nombre_proyecto",
			cellStyle: {
				width: "500px"
			},
		}, {
			title: "Código Proyecto",
			field: "codigo_proyecto",
			cellStyle: {
				whiteSpace: "nowrap"
			},
		}
	];

	return (
		< MaterialTable
			title="Personas en Talana"
			is_loading={is_loading}
			data={personas?.data}
			columns={columns}
			export_excel={true}
			export_spreadsheet={true}
		/>
	);
}

export default Page;