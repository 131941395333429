import React, { Fragment, useContext, useState } from "react";
import Icon from "@mdi/react";
import { mdiAlphaPCircle, mdiCheck, mdiMinus, mdiBadgeAccount, mdiFirebase } from "@mdi/js";
import { Tooltip, Typography } from "@material-ui/core";
import MaterialTable from "../../../components/materialTable";
import * as Permisos from "../../../constants/permissions";
import DialogAdd from "./dialogAdd";
import DialogUpdate from "./dialogUpdate";
import DialogDelete from "./dialogDelete";
import DialogImage from "./dialogCredencial";
import DialogFirebaseAuth from "./dialogFirebaseAuth";
import Filtros from "./filtros";
import { MainContext } from "../../../App";

function Page(props) {
	const {
		personas,
		is_loading,
		mutate_personas,
		handle_copy,
		handle_add_to_proyectos,
	} = props;

	const [IsOpen, SetIsOpen] = useState({ tipo: "", data: null });
	const [AnchorEl, SetAnchorEl] = useState(null);
	const { permisos } = useContext(MainContext);

	let columns = [
		{
			title: "RUN",
			field: "run_formateado",
			removable: false,
			cellStyle: {
				whiteSpace: "nowrap",
			},
		}, {
			title: "Nombre",
			field: "nombre_abreviado",
			removable: false,
			cellStyle: {
				whiteSpace: "nowrap",
			},
		}, {
			title: "Cargo",
			field: "cargo",
			removable: false,
		}, {
			title: "Título",
			field: "titulo",
			hidden: true,
		}, {
			title: "Email",
			field: "contacto.email",
			removable: false,
			render: (row) => (
				<Tooltip title="Click para copiar" onClick={() => handle_copy("Email", row.contacto.email)}>
					<Typography>{row.contacto.email}</Typography>
				</Tooltip>
			),
		}, {
			title: "Gerencia",
			field: "gerencia_ref.sigla",
		}, {
			title: "Centro de Costos",
			field: "centro_costos_ref[0].codigo",
			cellStyle: {
				whiteSpace: "nowrap",
			},
		}, {
			title: "Contrato",
			field: "contratos_ref[0].codigo",
			cellStyle: {
				whiteSpace: "nowrap",
			},
		}, {
			title: "Rol",
			field: "rol_ref.nombre",
		}, {
			title: "Email Alternativo",
			field: "contacto.email_alternativo",
			hidden: true,
		}, {
			title: "Email Personal",
			field: "contacto.email_personal",
			hidden: true,
		}, {
			title: "Teléfono",
			field: "contacto.telefono",
			hidden: true,
		}, {
			title: "Teléfono Alternativo",
			field: "contacto.telefono_alternativo",
			hidden: true,
		}, {
			title: "Teléfono Personal",
			field: "contacto.telefono_personal",
			hidden: true,
		}, {
			title: "Contratos",
			field: "contratos_visibles_ref.length",
			hidden: true,
		}, {
			title: "Proyectos",
			field: "proyectos_visibles_ref.length",
			hidden: true,
		}, {
			title: "Aplicaciones",
			field: "aplicaciones_visibles_ref.length",
			hidden: true,
		}, {
			title: "¿Habilitado?",
			field: "is_habilitado",
			render: (row) => CheckIcon(row.is_habilitado),
		}, {
			title: "Responsable?",
			field: "is_responsable",
			render: (row) => CheckIcon(row.is_responsable),
		}, {
			title: "¿Cliente?",
			field: "is_cliente",
			render: (row) => CheckIcon(row.is_cliente),
		}, {
			title: "¿Externo?",
			field: "is_externo",
			render: (row) => CheckIcon(row.is_externo),
		}, {
			title: "¿GSuite?",
			field: "is_gsuite",
			render: (row) => CheckIcon(row.is_gsuite),
		}, {
			title: "¿Talana?",
			field: "is_talana",
			render: (row) => CheckIcon(row.is_talana),
		}
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Actualizar",
			hidden: !permisos.includes(Permisos.PERSONAS_EDITAR),
			onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
		}, {
			icon: "delete",
			tooltip: "Eliminar (Requiere confirmación)",
			hidden: !permisos.includes(Permisos.PERSONAS_ELIMINAR),
			onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
		}, row => ({
			icon: () => <Icon path={mdiAlphaPCircle} size={1} color={row.proyecto ? "#00d230" : "#dc3545"} />,
			tooltip: row.proyecto ? "Copiar ID en Plataforma de Proyectos" : "Agregar a Plataforma de Proyectos",
			onClick: (event, row) => row.proyecto ? handle_copy("ID de Proyecto", row.proyecto) : handle_add_to_proyectos(row._id),
		}), {
			icon: () => <Icon path={mdiFirebase} size={1} />,
			tooltip: "Firebase Auth",
			onClick: (event, row) => SetIsOpen({ tipo: "firebase_auth", data: row }),
		}, row => ({
			icon: () => <Icon path={mdiBadgeAccount} size={1} color={row.photo_credential ? "#00d230" : "#dc3545"} />,
			tooltip: "Credencial",
			hidden: !permisos.includes(Permisos.PERSONAS_VER_CREDENCIAL),
			onClick: (event, row) => SetIsOpen({ tipo: "image", data: row }),
		}), {
			icon: "add",
			tooltip: "Agregar",
			isFreeAction: true,
			hidden: !permisos.includes(Permisos.PERSONAS_AGREGAR),
			onClick: (event) => SetIsOpen({ tipo: "add", data: null }),
		}, {
			icon: "filter_list",
			tooltip: "Filtros",
			isFreeAction: true,
			onClick: (event) => SetAnchorEl(event.currentTarget),
		}
	];

	const handleCloseDialog = () => {
		SetIsOpen({});
	}

	const handleCloseFiltros = () => {
		SetAnchorEl(null);
	};

	return (
		<Fragment>
			{/* LISTADO DE PERSONAS */}
			<MaterialTable
				title="Personas"
				is_loading={is_loading}
				data={personas}
				columns={columns}
				actions={actions}
				export_excel={true}
				export_spreadsheet={true}
			/>

			{/* DIALOG PARA AGREGAR */}
			{IsOpen.tipo === "add" && (
				<DialogAdd
					is_open={IsOpen.tipo === "add"}
					handle_close={handleCloseDialog}
					mutate_personas={mutate_personas}
				/>
			)}

			{/* DIALOG PARA ACTUALIZAR */}
			{IsOpen.tipo === "update" && (
				<DialogUpdate
					is_open={IsOpen.tipo === "update"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_personas={mutate_personas}
				/>
			)}

			{/* DIALOG PARA ELIMINAR */}
			{IsOpen.tipo === "delete" && (
				<DialogDelete
					is_open={IsOpen.tipo === "delete"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_personas={mutate_personas}
				/>
			)}

			{/* DIALOG PARA ACTUALIZAR */}
			{IsOpen.tipo === "firebase_auth" && (
				<DialogFirebaseAuth
					is_open={IsOpen.tipo === "firebase_auth"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_personas={mutate_personas}
					handle_copy={handle_copy}
				/>
			)}

			{IsOpen.tipo === "image" && (
				<DialogImage
					is_open={IsOpen.tipo === "image"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_personas={mutate_personas}
				/>
			)}

			{/* MENÚ DE FILTROS */}
			<Filtros
				anchor_el={AnchorEl}
				handle_close={handleCloseFiltros}
			/>
		</Fragment>
	);
}

/**
 * Método encargado de retornar un ícono Check o Uncheck.
 * @param {*} check Valor a verificar.
 * @returns Componente ícono.
 */
function CheckIcon(check) {
	return (
		<Icon path={check ? mdiCheck : mdiMinus} size={1} style={{ marginLeft: 25 }} />
	);
}

export default Page;