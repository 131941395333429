import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { MTableGroupRow } from "@material-table/core";
import { IconButton } from "@material-ui/core";
import { red, green, yellow } from "@material-ui/core/colors/";
import { Close as CloseIcon } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { ToExcel, ToSpreadsheet } from "../../services/data_export";

/**
 * Componente para hacer la tabla con lostado de datos.
 * @param {*} title Título de la tabla.
 * @param {*} is_loading Flag para determinar si está cargando los datos.
 * @param {*} data Datos para mostrar.
 * @param {*} columns Columnas seleccionadas para mostrar, con RENDER si aplica.
 * @param {*} actions Acciones (botones) por fila y libres.
 * @param {*} components Modificación de componentes (opcional).
 * @param {*} export_pdf Flag para determinar si se permite exportar datos en PDF.
 * @param {*} export_csv Flag para determinar si se permite exportar datos en CSV.
 * @param {*} export_excel Flag para determinar si se permite exportar datos en Excel.
 * @param {*} export_spreadsheet Flag para determinar si se permite exportar datos en Spreadsheet.
 * @param {*} filtering Flag para determinar si incluye Filtering.
 * @param {*} grouping Flag para determinar si incluye Grouping.
 * @param {*} searching Flag para determinar si incluye Search.
 * @param {*} empty_rows Flag para determinar si se rellena o no con filas vacías.
 * @param {*} exportButton Flag para determinar si se permite exportar los datos.
 * @param {*} columnsButton Flag para determinar si se mostrará el botón de las columnas.
 * @param {*} exportAllData Flag para determinar si se exportarán todos los datos o solo la página actual.
 * @param {*} page_size Tamaño inicial de elementos por página.
 */
function Index(props) {
	const {
		title,
		is_loading,
		data,
		columns,
		actions,
		components,
		export_pdf = false,
		export_csv = false,
		export_excel = false,
		export_spreadsheet = false,
		filtering = true,
		grouping = true,
		searching = true,
		empty_rows = false,
		exportButton = true,
		columnsButton = true,
		exportAllData = true,
		page_size = 20,
		lockheaders = false
	} = props;

	const [PageSize, SetPageSize] = useState(page_size);
	const [PageSizes, SetPageSizes] = useState([]);

	const notistack = useSnackbar();

	useEffect(() => {
		let pageSizes = [];
		if (data && data.length > 0) {
			pageSizes.push(Math.ceil(data.length / 10) * 1);
			pageSizes.push(Math.ceil(data.length / 10) * 2);
			pageSizes.push(Math.ceil(data.length / 10) * 5);
			pageSizes.push(Math.ceil(data.length / 10) * 10);
		}
		if (!pageSizes.find(a => a === page_size)) {
			pageSizes.push(page_size);
		}

		pageSizes.sort((a, b) => a - b);
		SetPageSizes(pageSizes);
	}, [data, page_size]);

	useEffect(() => {
		SetPageSize(PageSizes[0]);
	}, [PageSizes]);

	/**
	 * Método encargado de generar la colección de botones exportar disponibles.
	 * @returns Colección de botones exportar.
	 */
	function ExportMenu() {
		let menu = [];
		if (export_pdf) {
			menu.push({
				label: "Exportar PDF",
				exportFunc: (cols, datas) => ExportPdf(cols, datas, "pdf_export"),
			});
		}
		if (export_csv) {
			menu.push({
				label: "Exportar CSV",
				exportFunc: (cols, datas) => ExportCsv(cols, datas, "csv_export"),
			});
		}
		if (export_excel) {
			menu.push({
				label: "Exportar Excel",
				exportFunc: (cols, datas) => ToExcel(cols, datas, title, title),
			});
		}
		if (export_spreadsheet) {
			menu.push({
				label: "Exportar Spreadsheet",
				exportFunc: (cols, datas) => {
					notistack.enqueueSnackbar("Exportando los datos...", {
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
					});
					ToSpreadsheet(cols, datas, title, title)
						.then(response => {
							notistack.closeSnackbar();
							notistack.enqueueSnackbar(response.message, {
								variant: response.variant,
								anchorOrigin: {
									horizontal: "center",
									vertical: "bottom"
								},
								action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
							});
							if (response.url) {
								window.open(response.url, "_blank");
							}
						})
						.catch(error => {
							console.error(error);
							notistack.closeSnackbar();
							notistack.enqueueSnackbar("Error al intentar exportar los datos.", {
								variant: "error",
								anchorOrigin: {
									horizontal: "center",
									vertical: "bottom"
								},
								action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
							});
						});
				},
			});
		}
		return menu;
	}

	let options = {
		padding: "dense",
		pageSize: PageSize,
		pageSizeOptions: PageSizes,
		emptyRowsWhenPaging: empty_rows,
		columnsButton: columnsButton,
		exportButton: exportButton,
		exportMenu: ExportMenu(),
		exportAllData: exportAllData,
		search: searching,
		grouping: grouping,
		filtering: filtering,
		actionsColumnIndex: 0,
		headerStyle: {
			fontWeight: 'bold',
			color: "#731f1f",
			textAlign: "center",
		},
		maxBodyHeight: lockheaders ? "70vh" : "100%",
		rowStyle: rowData => ({
			backgroundColor: rowData.estado === "rechazado" ? red[500] : rowData.estado === "pendiente" ? yellow[500] : rowData.estado === "recibido" ? green[500] : "white"
		}),
	}

	let localComponents = {
		GroupRow: props => {
			//Permite mostrar la cantidad de elementos agrupados.
			let valor = props.groupData.value;
			let cantidad = props.groupData.data.length;
			props.groupData.value = `${valor} (${cantidad})`;
			return <MTableGroupRow {...props} />;
		}
	}

	return (
		<MaterialTable
			title={title}
			isLoading={is_loading}
			columns={columns}
			options={options}
			localization={location}
			actions={actions}
			data={data}
			components={Object.assign(localComponents, components)}
			style={{ borderRadius: "15px" }}
		/>
	);
}

const location = {
	"toolbar": {
		"addRemoveColumns": "Agregar o Quitar",
		"exportTitle": "Exportar",
		"exportCSVName": "Exportar Excel",
		"exportPDFName": "Exportar PDF",
		"nRowsSelected": "{0} fila(s) selecionadas",
		"searchPlaceholder": "Buscar",
		"searchTooltip": "Buscar",
		"showColumnsTitle": "Mostrar Columnas"
	},
	"pagination": {
		"labelRowsSelect": "Filas",
		"labelDisplayedRows": "{from}-{to} de {count}",
		"firstAriaLabel": "Primera Página",
		"firstTooltip": "Primera Página",
		"previousAriaLabel": "Página Anterior",
		"previousTooltip": "Página Anterior",
		"nextAriaLabel": "Siguiente Página",
		"nextTooltip": "Siguiente Página",
		"lastAriaLabel": "Última Página",
		"lastTooltip": "Última Página"
	},
	"grouping": {
		"placeholder": "Arrastre los encabezados aquí para agrupar "
	},
	"header": {
		"actions": "Acciones"
	},
	"body": {
		"emptyDataSourceMessage": "Sin Registros",
		"filterRow": {
			"filterTooltip": "Filtrar"
		}
	}
};

export default Index;