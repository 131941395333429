import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../../../constants/urls";

/**
 * Método encargado de obtener todos los roles.
 * @returns Colección de roles.
 */
export async function GetRoles() {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/roles-negocios`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener un rol, por su ID.
 * @param {*} rolID ID del rol.
 * @returns Datos del rol.
 */
export async function GetRol(rolID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/roles-negocios/${rolID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un rol.
 * @param {*} rol Datos del rol.
 * @returns Dato agregado.
 */
export async function AddRol(rol) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/roles-negocios`;
    let response = await Axios.post(url, rol);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un rol.
 * @param {*} rol Datos del rol.
 * @returns Dato actualizado.
 */
export async function UpdateRol(rol) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/roles-negocios/${rol._id}`;
    let response = await Axios.put(url, rol);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un rol.
 * @param {*} rolID ID del rol.
 * @returns Respuesta de la eliminación.
 */
export async function DeleteRol(rolID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/roles-negocios/${rolID}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}