import XLSX from "xlsx";
import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../constants/urls";
import { auth } from "./firebase";

/**
 * Método encargado de exportar los datos en formato Excel.
 * @param {*} cols Columnas de los datos a exportar.
 * @param {*} data Datos a exportar.
 * @param {*} tabName Nombre de la pestaña.
 * @param {*} fileName Nombre del archivo.
 */
export const ToExcel = (cols, data, tabName = "datos", fileName = "datos") => {
	const wb = XLSX.utils.book_new();
	data.unshift(Array.from(cols).map(c => c.title));
	let ws = XLSX.utils.aoa_to_sheet(data);
	XLSX.utils.book_append_sheet(wb, ws, tabName);
	XLSX.writeFile(wb, `${fileName}.xlsx`);
}

/**
 * Método encargado de exportar los datos en formato Spreadsheet.
 * @param {*} cols Columnas de los datos a exportar.
 * @param {*} data Datos a exportar.
 * @param {*} tabName Nombre de la pestaña.
 * @param {*} fileName Nombre del archivo.
 */
export const ToSpreadsheet = async (cols, data, tabName = "datos", fileName = "datos") => {
	try {
		data.unshift(Array.from(cols).map(c => c.title));
		let postData = {
			data: data,
			tab_name: tabName,
			file_name: fileName,
			owner: auth.currentUser.email,
		}
		let url = `${ADMINISTRACION_USUARIOS}/export-data`;
		let response = await Axios.post(url, postData);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}