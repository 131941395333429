import React, { useState } from "react";
import { Switch, Tooltip } from "@material-ui/core";
import useSWR from "swr";
import MaterialTable from "../../../components/materialTable";
import { GetContratos } from "../../../services/request/proyectos/contratos";

export default function TablaContratosVisibles(props) {
  const {
    seleccionados,
    handle_toggle,
  } = props;

  const [IncluirProyectos, SetIncluirProyectos] = useState(false);
  const { data: contratos, isValidating: isValidatingContratos } = useSWR("contratos_proyectos", GetContratos, { revalidateOnFocus: false });

  if (contratos) {
    //Se incluye flag CHECKED.
    contratos.forEach((contrato, index) => {
      let check = seleccionados.includes(contrato._id);
      contratos[index].checked = check;
    });

    //Se ordena por los contratos seleccionados.
    contratos.sort((a, b) => b.checked - a.checked);
  }

  let columns = [
    {
      title: "Visibilidad",
      removable: false,
      sorting: false,
      render: row => (
        <Tooltip title={IncluirProyectos ? `Contrato y ${row.proyectos_ref.length} Proyectos` : "Solo Contrato"} placement="top">
          <Switch checked={row.checked} onClick={(event) => handle_toggle(row._id, IncluirProyectos)} color="primary" />
        </Tooltip>
      ),
    }, {
      title: "Código",
      field: "codigo",
    }, {
      title: "Nombre",
      field: "nombre",
      render: (row) => <label style={{ width: 500 }}>{row.nombre}</label>,
    }, {
      title: "Cant. Proyectos",
      field: "proyectos_ref.length",
    }
  ];

  let actions = [
    {
      icon: "queue",
      tooltip: `Incluir Proyectos: ${IncluirProyectos ? "ON" : "OFF"}`,
      onClick: (event, row) => SetIncluirProyectos(!IncluirProyectos),
      isFreeAction: true,
      iconProps: {
        color: IncluirProyectos ? "primary" : "inherit",
      }
    }
  ];

  return (
    <MaterialTable
      title={CalcularCantidades(seleccionados, contratos)}
      is_loading={isValidatingContratos}
      data={contratos}
      columns={columns}
      actions={actions}
      filtering={false}
      grouping={false}
      exportButton={false}
    />
  );
}

/**
 * Método encargado de calcular la cantidad de seleccionados sobre el total.
 * @param {*} seleccionados Cantidad de seleccionados.
 * @param {*} total Cantidad total.
 * @returns Cantidades con formato X1 / X2.
 */
function CalcularCantidades(seleccionados, total) {
  let cantidad1 = seleccionados ? seleccionados.length : 0;
  let cantidad2 = total ? total.length : 0;
  return `Contratos Seleccionados: ${cantidad1} / ${cantidad2}`;
}