import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { UpdateAplicacion } from "../../../services/request/usuarios/aplicaciones";
import { GetContratos } from "../../../services/request/proyectos/contratos";
import { FormikInitialValues, FormikValidationSchema, CATEGORIAS, TIPOS, TIPOS_APLICACION } from "./utils";

export default function DialogUpdate(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_aplicaciones,
	} = props;

	const { data: contratosProyectos, isValidating: isValidatingContratosProyectos } = useSWR("contratos_proyectos", GetContratos, { revalidateOnFocus: false });

	const notistack = useSnackbar();

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				notistack.enqueueSnackbar("Actualizando la aplicación...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});
				values["_id"] = data._id;
				values["contrato_ref"] = {
					contrato_id: values.contrato._id,
					codigo: values.contrato.codigo,
					nombre: values.contrato.nombre,
				};
				values["tipo"] = values.tipo.label;
				values["tipo_app"] = values.tipo_app.value;
				values["categoria"] = values.categoria.label;
				await UpdateAplicacion(values);
				notistack.closeSnackbar();
				notistack.enqueueSnackbar("Aplicación actualizada exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar actualizar la aplicación.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			} finally {
				helper.resetForm();
				mutate_aplicaciones();
				handle_close();
			}
		}
	});

	const loadData = () => {
		formik.resetForm({
			values: {
				nombre: data.nombre,
				descripcion: data.descripcion,
				nombre_custom: data.nombre_custom,
				contrato: contratosProyectos ? Array.from(contratosProyectos).find(c => c._id === data.contrato_ref?.contrato_id) : null,
				tipo_app: TIPOS_APLICACION.find(t => t.value === data.tipo_app) || null,
				tipo: TIPOS.find(t => t.label === data.tipo) || null,
				categoria: CATEGORIAS.find(c => c.label === data.categoria) || null,
				logo: data.logo || "",
				link_ayuda: data.link_ayuda || "",
				link_carpeta_imagenes: data.link_carpeta_imagenes || "",
				link_carpeta_pdfs: data.link_carpeta_pdfs || "",
				link_template: data.link_template || "",
				link_template_cierre: data.link_template_cierre || "",
			}
		});
	}

	/**
	 * Método encargado de cerrar el popup y cerrar los paneles.
	 */
	const handleCloseDialog = () => {
		handle_close();
		formik.resetForm();
	}

	return (
		<Dialog open={is_open} onClose={handleCloseDialog} onEntered={loadData} maxWidth="md">
			<DialogTitle>Actualizar Aplicación</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{"Actualice la información necesaria de la aplicación y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formik.values.nombre}
							onChange={formik.handleChange}
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
							variant="outlined"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="descripcion"
							label="Descripción"
							value={formik.values.descripcion}
							onChange={formik.handleChange}
							helperText={formik.errors.descripcion}
							error={Boolean(formik.errors.descripcion)}
							variant="outlined"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="nombre_custom"
							label="Nombre Alternativo"
							value={formik.values.nombre_custom}
							onChange={formik.handleChange}
							helperText={formik.errors.nombre_custom}
							error={Boolean(formik.errors.nombre_custom)}
							variant="outlined"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							options={contratosProyectos}
							value={formik.values.contrato}
							loading={isValidatingContratosProyectos}
							getOptionLabel={(contrato) => `[${contrato.codigo}] ${contrato.nombre}`}
							onChange={(event, value) => formik.setFieldValue("contrato", value)}
							renderInput={(params) => (
								<TextField
									label="Contrato"
									helperText={formik.errors.contrato}
									error={Boolean(formik.errors.contrato)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							options={TIPOS_APLICACION}
							getOptionLabel={(tipoApp) => tipoApp.label}
							value={formik.values.tipo_app}
							onChange={(event, value) => formik.setFieldValue("tipo_app", value)}
							renderInput={(params) => (
								<TextField
									label="Tipo Aplicación"
									helperText={formik.errors.tipo_app}
									error={Boolean(formik.errors.tipo_app)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							options={TIPOS}
							getOptionLabel={(tipo) => tipo.label}
							value={formik.values.tipo}
							onChange={(event, value) => formik.setFieldValue("tipo", value)}
							renderInput={(params) => (
								<TextField
									label="Tipo"
									helperText={formik.errors.tipo}
									error={Boolean(formik.errors.tipo)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							options={CATEGORIAS}
							getOptionLabel={(categoria) => categoria.label}
							value={formik.values.categoria}
							onChange={(event, value) => formik.setFieldValue("categoria", value)}
							renderInput={(params) => (
								<TextField
									label="Categoría"
									helperText={formik.errors.categoria}
									error={Boolean(formik.errors.categoria)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="logo"
							label="Logo App"
							value={formik.values.logo}
							onChange={formik.handleChange}
							helperText={formik.errors.logo}
							error={Boolean(formik.errors.logo)}
							variant="outlined"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="link_ayuda"
							label="Link Ayuda"
							value={formik.values.link_ayuda}
							onChange={formik.handleChange}
							helperText={formik.errors.link_ayuda}
							error={Boolean(formik.errors.link_ayuda)}
							variant="outlined"
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="link_carpeta_pdfs"
							label="Carpeta PDFs"
							value={formik.values.link_carpeta_pdfs}
							onChange={formik.handleChange}
							helperText={formik.errors.link_carpeta_pdfs}
							error={Boolean(formik.errors.link_carpeta_pdfs)}
							variant="outlined"
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="link_carpeta_imagenes"
							label="Carpeta Imágenes"
							value={formik.values.link_carpeta_imagenes}
							onChange={formik.handleChange}
							helperText={formik.errors.link_carpeta_imagenes}
							error={Boolean(formik.errors.link_carpeta_imagenes)}
							variant="outlined"
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="link_template"
							label="Template Documento"
							value={formik.values.link_template}
							onChange={formik.handleChange}
							helperText={formik.errors.link_template}
							error={Boolean(formik.errors.link_template)}
							variant="outlined"
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="link_template_cierre"
							label="Template Documento Cierre"
							value={formik.values.link_template_cierre}
							onChange={formik.handleChange}
							helperText={formik.errors.link_template_cierre}
							error={Boolean(formik.errors.link_template_cierre)}
							variant="outlined"
							fullWidth
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}